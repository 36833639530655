
import { defineComponent } from 'vue';
import GridLine from '@/components/GridLine.vue';

export default defineComponent({
  name: 'Company',
  components: {
    GridLine,
  },
});
